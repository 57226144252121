@font-face {
    font-family: 'Gotham Pro';
    src: url('../fonts/GothamPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Bold Italic';
    src: url('../fonts/GothamPro-BoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Italic';
    src: url('../fonts/GothamPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Light';
    src: url('../fonts/GothamPro-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Medium';
    src: url('../fonts/GothamPro-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Medium Italic';
    src: url('../fonts/GothamPro-MediumItalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro Black';
    src: url('../fonts/GothamPro-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Sofia Pro";
    src: url('../fonts/sofiapro-light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand";
    src: url('../fonts/Quicksand-Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Light";
    src: url('../fonts/Quicksand-Light.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Medium";
    src: url('../fonts/Quicksand-Medium.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Semibold";
    src: url('../fonts/Quicksand-SemiBold.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Bold";
    src: url('../fonts/Quicksand-Bold.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Variable Font";
    src: url('../fonts/Quicksand-VariableFont_wght.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

